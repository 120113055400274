<template>
  <!-- ==============================================
  **Footer opt1**
  ===================================================-->
  <footer class="footer d-print-none">
    <div class="container bottom">
      <div class="row">
        <div class="col-md-9 col-12">
          <div class="row">
            <div class="col-md-6 col-12 mob-acco text-light">
              <img :src="`${baseURL}/assets/images/CM-Logo.png`" class="img-fluid mt-2 mb-2" alt=""
                style="height: 60px;" />
              <div class="w-100 mt-2 mb-2">
                <span id="siteseal"></span>
              </div>
              <span> Copyright © {{ this.date }} Circleram. All Rights Reserved.</span>
            </div>
            <div class="col-md-3 col-12 mb-sm-2">
              <div class="quick-links">
                <p class="text-light mb-2">SITE MAP</p>
                <ul class="d-block">

                  <li>
                    <a href="https://circleram.com/index.aspx" id="lnkFooterHome">Home</a>
                  </li>
                  <li>
                    <a href="https://cm.circleram.com/home.aspx" id="lnkFooterLearn">Learn</a>
                  </li>
                  <li>
                    <a href="https://circleram.com/aboutus.aspx" id="lnkFooterAboutUs">About Us</a>
                  </li>
                  <li>
                    <a href="https://circleram.com/privacy.aspx" id="lnkFooterPrivacy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://circleram.com/refund.aspx" id="lnkFooterRefund">Refund Policy</a>
                  </li>
                  <li>
                    <a href="https://circleram.com/terms.aspx" id="lnkFooterTerms">Term of Service</a>
                  </li>
                  <li>
                    <a href="https://dl.circleram.com/blogs.aspx" id="lnkFooterBlog">Blog</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 col-12 mb-sm-2">
              <div class="quick-links">
                <p class=" mb-2">SOCIAL MEDIA</p>
                <ul class="d-inline">
                  <li>
                    <span class="mr-1"> <img style="height: 16px;" src="assets/images/icon/twitter.svg " alt=""></span> <a href="#" id="lnkFooterRefund">Twitter</a>
                  </li>
                  <li>
                    <span class="mr-1"> <img style="height: 16px;" src="assets/images/icon/pinterest.svg " alt=""></span> <a href="#" id="lnkFooterRefund">Pinterest</a>
                  </li>
                  <li>
                    <span class="mr-1"> <img style="height: 16px;" src="assets/images/icon/facebook.svg " alt=""></span> <a href="#" id="lnkFooterRefund">Facebook</a>
                  </li>
                  <li>
                    <span class="mr-1"> <img style="height: 16px;" src="assets/images/icon/instagram.svg " alt=""></span> <a href="#" id="lnkFooterRefund">Instagram</a>
                  </li>

                </ul>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-3 col-12">
          <div class="quick-links">
            <p class=" mb-2">CONTACT US</p>
            <ul class="d-inline">
              <li>
                <a href="mailto:support@circleram.com">Support: support@circleram.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-12 d-flex justify-content-end">
          <div class="text-light">
            Powered by: Course Manager - <a href="https://circleram.com/" target="_blank" class="text-warning">CircleRAM</a>
          </div>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      date: new Date().getFullYear(),
      supportURL: process.env.VUE_APP_SUPPORT_BASE_URL
    }
  },
  mounted() {
    let starfieldtechScript = document.createElement('script')
    starfieldtechScript.setAttribute('src', 'https://seal.starfieldtech.com/getSeal?sealID=HaarvVeYc7boSNbd0cOQIraekT151LSQtZasEiJadaLNpFs9x6SB3DjIm9kk')
    document.head.appendChild(starfieldtechScript)
  }
}
</script>
